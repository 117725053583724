<template>
  <div>
    <b-card :title="$t('reports.report_settings')">
    <b-row>

      <!-- report name -->
      <b-col sm="12" md="6">
        <b-form-group
          :label="$t('reports.report_name')"
          label-for="v-report-name"
        >
          <b-form-input
            id="v-report-name"
            :value="report_name"
            @input="(val) => $emit('update:report_name', val)"
          />
        </b-form-group>
      
      <!-- report lang -->
          <b-form-group
            :label="$t('fields.lang')"
            label-for="account-lang"
          >
          <v-select
            label="name"
            :value="report_lang"
            :options="lang_options"
            class="w-100"
            :clearable="false"
            :reduce="val => val.iso_code"
            @input="(val) => $emit('update:report_lang', val)"
          />
          </b-form-group>
      </b-col> 
    
      <!-- DateRange Buttons -->
      <b-col sm="12" md="6">
        <div class="text-right">
        <b-button-group class="mb-1 mt-2 ">
          <b-button
            variant="outline-primary"
            @click="setLastWeek"
          >
            {{ $t('reports.last_week') }}
          </b-button>
          <b-button
            variant="outline-primary"
            @click="setLastMonth"
          >
            {{ $t('reports.last_month') }}
          </b-button>
          <b-button
            variant="outline-primary"
            @click="setLastYear"
          >
            {{ $t('reports.last_year') }}
          </b-button>
        </b-button-group>
        </div>
        <b-row>
          <b-col sm="12" md="6">
            <b-form-group
              :label="$t('reports.startDate')"
              label-for="startDate"
            >
              <b-form-datepicker
                id="startDate"
                :placeholder="$t('reports.select')"
                :value="startDate"
                start-weekday="1"
                class="mb-1"
                :locale="getLocal"
                @input="(val) => $emit('update:startDate', val)"
                
                :max="maxDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              />
            </b-form-group>
          </b-col>
          <!-- endDate -->
          <b-col sm="12" md="6">
            <b-form-group
              :label="$t('reports.endDate')"
              label-for="endDate"
            >
              <b-form-datepicker
                id="endDate"
                :placeholder="$t('reports.select')"
                :value="endDate"
                start-weekday="1"
                class="mb-1"
                :locale="getLocal"
                @input="(val) => $emit('update:endDate', val)"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                :min="minDate"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <!-- startDate -->
      
    </b-row>
    </b-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'

import { 
   BRow, BCol, BFormGroup, 
   BFormInput,
   BButtonGroup, BButton,
    BFormDatepicker, BCard, 


    VBTooltip
} from 'bootstrap-vue'
export default {
  components:{
    BRow, BCol, BFormGroup, 
    BButtonGroup, BButton,
    BFormDatepicker, BCard, 

   BFormInput,
   vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props:{
    report_name:{
      type: String,
      required: true
    },
    report_lang:{
      type: String,
      required: true
    },
    startDate:{
      type: String,
      required: false
    },
    endDate:{
      type: String,
      required: false
    },
  },
  data(){
    const now = new Date()

    return {
      lang_options:[],
      now
    }
  },
  mounted(){
    this.lang_options = this.$store.getters['general/getLangs']
    // console.log(this.lang_options)
  },
  computed:{
    getLocal(){
      return this.$store.getters['user/getLang']
    },
    maxDate(){
      return this.endDate ?? null
    },
    minDate(){
      return this.startDate ?? null
    }
  },
  methods:{
    setLastWeek(){
      let val = new Date(this.now)
      val.setDate(val.getDate() -7)
      this.$emit('update:startDate', val.toISOString().slice(0, 10))
      this.$emit('update:endDate', this.now.toISOString().slice(0, 10))
    },
    setLastMonth(){
      let val = new Date(this.now)
      val.setMonth(val.getMonth() -1)
      this.$emit('update:startDate', val.toISOString().slice(0, 10))
      this.$emit('update:endDate', this.now.toISOString().slice(0, 10))
    },
    setLastYear(){
      let val  = new Date(this.now)
      val.setFullYear(val.getFullYear() -1)
      this.$emit('update:startDate', val.toISOString().slice(0, 10))
      this.$emit('update:endDate', this.now.toISOString().slice(0, 10))
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>