<template>
  <b-row class="mb-2 mt-2" v-if="wizard_step != 1">
    <b-col cols="12">
      <b-button style="float: left;" 
        class="mb-1"
        v-b-tooltip.hover.top="$t('learning_path.go_back')"
        v-if="wizard_step != 1" 
        variant="outline-secondary" 
        @click="$emit('handle-wizard-step', 'prev')">
          <span class="d-none d-md-block">{{$t('learning_path.go_back')}}</span>
          <span class="d-block d-md-none">
            <feather-icon icon="ChevronsLeftIcon" />
          </span>
      </b-button>
      <b-button v-if="wizard_step == 2" 
        style="float: right;" 
        @click="info_errors?$emit('show-error'):$emit('handle-wizard-step', 'next')">{{$t('reports.continue')}}</b-button>
      <template v-if="wizard_step == 3">
        <b-button class="ml-1 mb-1" style="float: right;" 
          v-b-tooltip.hover.top="$t('reports.select_by_path')"
          @click="dreamers_groups_errors?$emit('show-error'):$emit('handle-wizard-step', 'next'); $emit('select-content-type', 'byPaths')">
          <span class="d-none d-md-block">{{$t('reports.select_by_path')}}</span>
          <span class="d-block d-md-none">
            <feather-icon icon="CompassIcon" />
          </span>
        </b-button>
        <b-button class="mb-1" style="float: right;" 
            v-b-tooltip.hover.top="$t('reports.select_by_content')"
          @click="dreamers_groups_errors?$emit('show-error'):$emit('handle-wizard-step', 'next'); $emit('select-content-type', 'byContents')">
          <span class="d-none d-md-block">{{$t('reports.select_by_content')}}</span>
          <span class="d-block d-md-none">
            <feather-icon icon="BoxIcon" />
          </span>
          </b-button>
      </template>
      <b-button v-if="wizard_step == 4" 
        style="float: right;" 
        @click="lps_contents_errors?$emit('show-error'):$emit('handle-wizard-step', 'next')">{{$t('reports.continue')}}</b-button>
      <b-button v-if="wizard_step == 5" 
        style="float: right;" 
        @click="$emit('handle-wizard-step', 'save')">{{$t('reports.make_report')}}</b-button>
    </b-col>
  </b-row>

</template>

<script>
import {
  VBTooltip,
  BCard, BCardHeader, BCardBody, BCardFooter, BButton, BRow, BCol
} from 'bootstrap-vue'
export default {
  components:{
    BCard, BCardHeader, BCardBody, BCardFooter, BButton, BRow, BCol
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props:{
    wizard_step:{
      type: Number,
      required: true
    },
    dreamers_groups_errors:{
      type: Boolean,
      required: true,
    },
    lps_contents_errors:{
      type: Boolean,
      required: true
    },
    info_errors:{
      type: Boolean,
      required: true
    }
  }
}
</script>

<style>

</style>