<template>
  <div  class="ecommerce-application">
    <div class="checkout-options">
      <h3>{{$t('learning_path.summary')}}</h3>
      <p>{{$t('Verify that the information is correct before creating the report.')}}</p>
      <b-card>

        <h4>{{$t('resources.title')}}: {{report_name}}</h4>
        <p>{{$t('fields.lang')}}: {{report_lang.toUpperCase()}}</p>
        <p>{{$t('date range')}}: <label class="section-label mt-25">{{new Date(startDate).toLocaleDateString()}} - {{new Date(endDate).toLocaleDateString()}}</label></p>
        
        <hr>
        <div class="price-details">
          <h6 class="price-title">
            {{$t('type of report')}}: {{reportType == 'summary' ? $t('reports.general_report'): reportType == 'groups' ? $t('reports.groups_report') : $t('reports.dreamers_report') }}
          </h6>
          <ul class="list-unstyled" v-if="reportType != 'summary'">
            <li>
              <div class="detail-title">
                {{reportType == 'groups'? $t('Groups') : $t('Dreamers') }}
              </div>
              <div class="d-block mt-1 mb-1">
                <b-avatar-group size="33">
                  <b-avatar
                    :rounded="reportType=='groups'"
                    v-for="item in selected_groups_dreamers"
                    :key="item.id"
                    v-b-tooltip.hover="item.name"
                    class="pull-up badge-light"
                    :src="resolveAvatars(item)"
                  />
                </b-avatar-group>
              </div>
            </li>
            <li>
              <div class="detail-title">
                {{contentsType == 'byContents' ? $t('Contents') : $t('Learning Paths')}}
              </div>
              <div class="d-block mt-1 mb-1">
                <b-avatar-group size="33">
                  <b-avatar
                    v-for="item in selected_lps_contents"
                    :key="item.id"
                    v-b-tooltip.hover="item.title"
                    class="pull-up badge-light"
                    :src="resolveIcons(item)"
                  />
                </b-avatar-group>
              </div>
            </li>
          </ul>


        </div>

      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton, BCard, BInputGroup, BFormInput, BInputGroupAppend, BAvatarGroup, BAvatar, 
  VBTooltip
} from 'bootstrap-vue'

export default {
  components:{
    BButton, BCard, BInputGroup, BFormInput, BInputGroupAppend,BAvatarGroup, BAvatar,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props:[
    'report_name',
    'report_lang',
    'startDate',
    'endDate',
    'contentsType',
    'reportType',
    'selected_groups_dreamers',
    'selected_lps_contents'
  ],
  methods:{
    resolveAvatars(item){
      let avatar = '';
      switch (this.reportType) {
        case 'dreamers':
          avatar = require('@/assets/images/avatar/AvatarSprite_'+(item.avatar||'57')+'.png?v4')
          break;
        case 'groups':
          if (item.id==0) {
            avatar = require('@/assets/images/avatar/groups/null.png?v4')          
          } else{
            avatar = require('@/assets/images/avatar/groups/'+(item.id_avatar||'null')+'.png?v4')
          }
          break;
        default:
          break;
      }
      return avatar;
    },
    resolveIcons(item){
      let avatar = '';
      switch (this.contentsType) {
        case 'byContents':
          avatar = item.icon_url
          break;
        case 'byPaths':
          avatar = require('@/assets/images/learning_paths/'+(item.img||'1')+'.png?v4')
          break;
        default:
          break;
      }
      return avatar;
    },
  }
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';

</style>