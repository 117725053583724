<template>
  <div>
    <custom-breadcrumb
      :pageTitle="$t('reports.make_report')"
      :breadcrumb="[
              {
                text: $t('Reports'),
                to: 'reports',
              }
            ]"
    ></custom-breadcrumb>
    <wizard-steps-buttons 
      :wizard_step="wizard_step" 
      @handle-wizard-step="handleWizardStep"
      @select-content-type="selectContentType"

      @show-error="showError"
      :dreamers_groups_errors="reportType=='groups' ? groups_errors : dreamers_errors"
      :lps_contents_errors="contentsType=='byContents' ? contents_errors : lps_errors"
      :info_errors="info_errors"
    />

    <section>
      <!-- <p>STATE</p>
      <p>contentsType: {{contentsType}}</p>
      <p>reportType: {{reportType}}</p>
      <p>wizard_step: {{wizard_step}}</p>
      <p>selected_groups: {{ selected_groups}}</p>
      <p>selected_dreamers: {{ selected_dreamers}}</p>
      <p>selected_contents: {{ selected_contents}}</p> 
      <p>selected_lps: {{ selected_lps}}</p>
      <p>Name{{ report_name}}; lang:{{report_lang}}</p>
      <p>startDate{{ startDate}}; endDate:{{endDate}}</p>
      <hr>-->


      <template v-if="wizard_step==1">
        <report-type
          @handle-wizard-step="handleWizardStep"
          @select-report-type="selectReportType"
        />
      </template>
      
      <template v-if="wizard_step==2">
        <report-info 
          :report_name.sync="report_name"
          :report_lang.sync="report_lang"
          :startDate.sync="startDate"
          :endDate.sync="endDate"
        />
      </template>

      <template v-if="wizard_step==3">
        <groups-selection 
          v-if="reportType=='groups'"
          @groups-selected="groupsSelected"
          :selected_groups="selected_groups"
          :startDate="startDate"
          :endDate="endDate"
         />
        <dreamers-selection 
          v-if="reportType=='dreamers'" 
          :selected_dreamers="selected_dreamers"
          @dreamers-selected="dreamersSelected"
          :startDate="startDate"
          :endDate="endDate"
        />
      </template>
      <template v-if="wizard_step==4">
        <learning-paths-selection 
          v-if="contentsType=='byPaths'" 
          :report_lang="report_lang"
          :ids="resolveChildren()"
          :isGroup="reportType=='groups'"
          :selected_lps="selected_lps"
          @lps-selected="lpsSelected"
          :startDate="startDate"
          :endDate="endDate"
        />
        <contents-selection 
          v-if="contentsType=='byContents'" 
          :report_lang="report_lang"
          :ids="resolveChildren()"
          :isGroup="reportType=='groups'"
          :selected_contents="selected_contents"
          @contents-selected="contentsSelected"
          :startDate="startDate"
          :endDate="endDate"
        />
      </template>
      
      <template v-if="wizard_step==5">
        <report-summary 
          :report_name="report_name"
          :report_lang="report_lang"
          :startDate="startDate"
          :endDate="endDate"
          :contentsType="contentsType"
          :reportType="reportType"
          :selected_groups_dreamers="reportType == 'groups'?selected_groups:selected_dreamers"
          :selected_lps_contents="contentsType=='byContents'?selected_contents:selected_lps"
          />
      </template>
      
      
    </section>

    <wizard-steps-buttons 
      :wizard_step="wizard_step" 
      @handle-wizard-step="handleWizardStep"
      @select-content-type="selectContentType"

      @show-error="showError"
      :dreamers_groups_errors="reportType=='groups' ? groups_errors : dreamers_errors"
      :lps_contents_errors="contentsType=='byContents' ? contents_errors : lps_errors"
      :info_errors="info_errors"

    />
  </div>
</template>

<script>
import { heightFade } from '@core/directives/animations'

import { sendDataToMakeReport } from '@/api/reports'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import ContentsSelection from './WizardComponents/ContentsSelection.vue'
import DreamersSelection from './WizardComponents/DreamersSelection.vue'
import GroupsSelection from './WizardComponents/GroupsSelection.vue'
import LearningPathsSelection from './WizardComponents/LearningPathsSelection.vue'
import ReportInfo from './WizardComponents/ReportInfo.vue'
import ReportSummary from './WizardComponents/ReportSummary.vue'
import ReportType from './WizardComponents/ReportType.vue'
import WizardStepsButtons from './WizardComponents/WizardStepsButtons.vue'
import {
  BAlert, BCard, BCardHeader, BCardBody, BCardFooter, BButton, BRow
} from 'bootstrap-vue'
export default {
  name: "CreateReport",
  components:{
    ContentsSelection,
    DreamersSelection,
    GroupsSelection,
    LearningPathsSelection,
    ReportInfo,
    ReportSummary,
    ReportType,
    WizardStepsButtons,

    CustomBreadcrumb,

    BAlert, BCard, BCardHeader, BCardBody, BCardFooter, BButton, BRow

  },
  directives: {
    'height-fade': heightFade,
  },
  data(){
    return{
      wizard_step: 1,


      contentsType: "",
      reportType: "",
      selected_groups: [],
      selected_dreamers: [],
      selected_lps: [],
      selected_contents: [],

      report_name: "",
      report_lang: "es",
      startDate: null,
      endDate: null
    }
  },
  mounted(){
    this.report_lang = this.$store.getters['user/getLang']
  },
  computed:{
    groups_errors(){
      return this.selected_groups.length == 0
    },
    dreamers_errors(){
      return this.selected_dreamers.length == 0
    },
    lps_errors(){
      return this.selected_lps.length == 0
    },
    contents_errors(){
      return this.selected_contents.length == 0
    },
    info_errors(){

      let all_ok = false
      if(!this.report_name){
        // console.log(!this.report_name, "!this.report_name")
        all_ok = true
      } else if(!this.report_lang){
        all_ok = true
      } else if(!this.startDate){
        all_ok = true
      } else if(!this.endDate){
        all_ok = true
      }
      return all_ok
    }
  },
  watch:{
    selected_dreamers(){
      /* When dreamers selection change, clear LPs and contents array */
      this.selected_lps = []
      this.selected_contents = []      
    },
    selected_groups(){
      /* When groups selection change, clear LPs and contents array */
      this.selected_lps = []
      this.selected_contents = []      
    },
  },
  methods:{
    showError(){
      this.makeToast('danger', this.$t('Error'), this.resolveAlertText());
    },
    resolveAlertText(){
      if(this.wizard_step==3){
        return this.reportType=='groups'? this.$t('reports.report_groups_missing') : this.$t('reports.report_dreamers_missing')
      }
      if(this.wizard_step==4){
       return this.contentsType=='byContents' ? this.$t('reports.report_contents_missing') : this.$t('reports.report_paths_missing')
      }
      if(this.wizard_step==2){
        if(!this.report_name){
          return this.$t('reports.report_name_missing')
        } else if(!this.report_lang){
          return this.$t('learning_path.pick_a_lang')
        } else if(!this.startDate || !this.endDate){
          return this.$t('customize.date_missing')
        }
      }
    },
    /* resolveAlertShow(){
      if(this.wizard_step==2){
        return this.reportType=='groups'?this.groups_errors: this.dreamers_errors
      }
    }, */
    resolveChildren(){
      if(this.reportType=='dreamers'){
        return this.selected_dreamers
      } else {
        return this.selected_groups
      }
    },
    selectContentType(contentType){
      this.contentsType = contentType  
    },
    selectReportType(reportType){
      this.reportType = reportType
      if(this.reportType == "summary"){
        this.contentsType = ""
      }
    },
    handleWizardStep(action){
      switch (action) {
        case 'prev':
          if(this.reportType=="summary" && this.wizard_step == 5){
            this.wizard_step = 2
          } else {
            this.wizard_step = this.wizard_step -1
            if(this.wizard_step == 1){
              // this.refreshForm() --> borrar todos los datos?
            }
          }
          break;
        case 'next':
          if(this.reportType=="summary" && this.wizard_step == 2){
            this.wizard_step = 5
          } else {
            this.wizard_step = this.wizard_step +1
          }
          break;
        case 'summary':
          this.wizard_step = 2
          break;
        case 'save':
          this.saveForm()
          break;
        default:
          break;
      }
    },
    groupsSelected(selected_groups){
      this.selected_groups = selected_groups
    },
    dreamersSelected(selected_dreamers){
      this.selected_dreamers = selected_dreamers
    },
    lpsSelected(selected_lps){
      // console.log("this.selected_lps", this.selected_lps)
      this.selected_lps = selected_lps
    },
    contentsSelected(selected_contents){
      this.selected_contents = selected_contents
    },
    async saveForm(){
      let data ={
        'user': this.$store.getters['user/getUserData'].id,
        'token': this.$store.getters['user/getUserData'].sso,

        'report_name': this.report_name,
        'lang' : this.report_lang,
        'start_date':  new Date(this.startDate).getTime(),
        'end_date': new Date(this.endDate).getTime(),
        'type': this.reportType,
      }
      
      if(this.reportType == 'groups'){
          data['groups']= this.selected_groups.map( k => k.id )
      }
      if(this.reportType == 'dreamers'){
          data['dreamers']= this.selected_dreamers.map( k => k.id )
      }

      if(this.contentsType == 'byPaths'){
          data['learning_paths']= this.selected_lps.map( k => k.id )
      } 
      if(this.contentsType == 'byContents'){
          data['contents'] = this.selected_contents.map( k => k.id )
      } 
      
     await this.$http.post(sendDataToMakeReport, data).then( response =>{
          if(response.data.status == 200){
            this.makeToast('success', this.$t('reports.generating_report'), this.$t('reports.download_from_documents'));
            this.$router.push({ name: 'reports' })
          } else if (response.data.status == 404) {
            this.makeToast('danger', this.$t('Error'), this.$t('reports.empty_report'));
          } else if (error.response.data.status == 422) {
            this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
          } else {
            this.makeToast('danger', this.$t('Error'), this.$t('reports.error_generating_report'));
          }
      }).catch(error => {
        if (error.response){
          if (error.response.data.status == 404){
            this.makeToast('danger', this.$t('Error'), this.$t('reports.empty_report'));
          } else if (error.response.data.status == 422) {
            this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
          }
        } else {
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        }
      })
      
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style lang="scss">
</style>
