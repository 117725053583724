<template>
  <div>
    <b-row v-if="loading">
      <b-col cols="12"  >
        <div class="text-center">
          <b-spinner label="Loading..." />
        </div>
      </b-col>
    </b-row>
    <template v-else>
      <b-row>
        <b-col cols="12" >
          <learning-path-list 
            :isReport="true" 
            :selected_lps="selected_lps"
            @lps-selected="lpsSelected"
          />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import LearningPathList from '@/views/learning-paths/LearningPathList.vue'
import { BRow, BCol, BSpinner } from 'bootstrap-vue'
import { getLearningPathsForReports } from '@/api/reports'

export default {
  components:{
    LearningPathList,
    BRow, BCol, BSpinner
  },
  props:{
    report_lang:{
      type: String,
      required: true
    },
    ids:{
      type: Array,
      required: true
    },
    isGroup:{
      type: Boolean,
      required: true
    },
    selected_lps:{
      type: Array,
      required: true
    },
    endDate:{
      type: String,
      required: true
    },
    startDate:{
      type: String,
      required: true
    }
  },
  data(){
    return {
      loading: true,
    }
  },
  mounted(){
    this.getData()
  },  
  methods: {
    lpsSelected(selected_lps){
      this.$emit('lps-selected', selected_lps)
    },
    async getData(){
      
      let data = {
          'user_id':  this.$store.getters['user/getUserData'].id,
          'token': this.$store.getters['user/getUserData'].sso,
          "lang": this.report_lang,
          "ids": this.ids.map(x => x.id),
          "is_group": this.isGroup,
          'start_date':  new Date(this.startDate).getTime(),
          'end_date': new Date(this.endDate).getTime(),
      } 

      await this.$http.post(getLearningPathsForReports, data).then( response =>{
          // console.log(response.data, "--getLearningPathsForReports-----------------")
          if(response.status == 200){
              /* if(this.$store.getters['user/getUserData'].id == 1081){
                let myHierarchy = [
                  { "source":"smileandlearn", "subcategories": response.data.hierarchy } 
                ]
                this.$store.commit('learningPaths/setHierarchy', myHierarchy )
              } else { */
                let myHierarchy = [
                   { "source":"all" },
                ]
                this.$store.commit('learningPaths/setHierarchy', myHierarchy )
              //}    
              this.$store.commit('learningPaths/setContents', response.data.contents)
              this.$store.commit('learningPaths/setLearningPaths', response.data.lps)
            
            this.loading = false
          } else {
            this.makeToast('danger', this.$t('Error'), this.$t('message.'+response.data.msg_code));
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style>

</style>