<template>
  <div>
    <b-row>
      <b-col cols="12">
        <h4 class="mb-3">
          {{$t('reports.select_report_type')}}
        </h4>
      </b-col>
      <b-col cols="12">
        <b-card-group
          deck
          class="mb-0"
        >
          <b-card
            v-for="(option,index) in reportTypeOptions"
            :key="index"
            :img-src="require('@/assets/images/teaching-resources/' + option.img)"
            img-alt="Card image cap"
            img-top
            no-body
            class="pointer"
            @click="$emit('handle-wizard-step', option.type=='summary'?'summary':'next'); 
                    $emit('select-report-type', option.type)"
          >
            <b-card-body>
              <b-card-title>{{ $t('reports.'+option.title) }}</b-card-title>
              <b-card-text>
                <span v-html="$t('reports.'+option.description)"></span>
              </b-card-text>
              
            </b-card-body>
            <!-- <b-card-footer>
              <b-button style="float: right;" 
                @click="$emit('handle-wizard-step', option.type=='summary'?'summary':'next'); 
                        $emit('select-report-type', option.type)"
              >
                <feather-icon
                  icon="ChevronsRightIcon"
                  size="18"
                  class="mr-50"
                />
                {{ $t('reports.select') }}
              </b-button>
            </b-card-footer> -->
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BCardGroup, BCard, BCardText, BCardTitle, BCardFooter, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
  BButton, BRow, BCol, BCardGroup, BCard, BCardText, BCardTitle, BCardFooter, BCardBody,
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      reportTypeOptions: [
        {
          img: "inf_general.png",
          title: 'general_report',
          description: 'general_report_info',
          type:"summary",
        },
        {
          img: "inf_grup.png",
          title: 'groups_report',
          description: 'groups_report_info',
          type:"groups"
        },
        {
          img: "inf_indiv.png",
          title: 'dreamers_report',
          description:'dreamers_report_info',
          type:"dreamers"
        },
      ]
    }
  }
}
</script>

<style>

</style>